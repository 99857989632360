<template>
  <transition name="fade">
    <div v-if="show" class="modal-wrapper" @click="$emit('close')">
      <div class="modal-content" @click="$event.stopPropagation()">
        <div class="refund-content">
          <div class="content">
            <button class="btn-close" @click="$emit('close')">
              <img src="../../assets/img/btn_close.png"/>
            </button>
            <label class="title">{{ $t('refund.refund_agree_10') }}</label>
            <div class="first">
              <dl id="sample" class="dropdown">
                <dt @click="toggle">
                  <span class="value">{{ selectedBank }}</span>
                  <img src="../../assets/img/ico_down.png"/>
                </dt>
                <dd>
                  <ul>
                    <li class="country-item" v-for="(item, idx) in bankList" :key="idx" @click="onSelect(item, idx)">
                      <span class="value">{{ item }}</span>
                    </li>
                  </ul>
                </dd>
              </dl>
              <span class="separator"/>
              <label>
                33331234353434
              </label>
            </div>
            <div style="flex-direction: column; width: 100%">
              <label class="option">
                {{ $t('refund.owner') }}
              </label>
              <input v-model="owner" :placeholder="$t('refund.refund_03')" type="text"/>
            </div>
            <div style="flex-direction: column; width: 100%">
              <label class="option">
                {{ $t('refund.phone_desc') }}
              </label>
              <input v-model="phone" :placeholder="$t('refund.refund_05')" type="tel"/>
            </div>
            <div style="flex-direction: column; width: 100%">
              <label class="option">
                {{ $t('refund.resident_desc') }}
              </label>
              <input v-model="resident" :placeholder="$t('refund.refund_08')" type="number"/>
            </div>
            <div style="flex-direction: column; width: 100%">
              <label class="option">
                {{ $t('refund.address_desc') }}
              </label>
              <input v-model="phone" :placeholder="$t('refund.refund_04')" type="text"/>
            </div>
            <div style="flex-direction: column; width: 100%">
              <label class="option">
                {{ $t('refund.cash_desc') }}
              </label>
              <input v-model="cash" :placeholder="$t('refund.refund_10')" type="number"/>
            </div>
            <div>
              <button class="minus-button" @click="onMinus">
                -
              </button>
              <button class="minus-button plus" @click="onPlus">
                +
              </button>
              <button class="max-button">
                {{ $t('common.max') }}
              </button>
            </div>
            <button class="button btn-ask" @click="onRequest">
              {{ $t('refund.refund_28') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import {loadData, requestPost, saveData, showToast} from "../../lib/util";
import $ from "jquery";

export default {
  name: 'Refund',
  components: {},
  emits: ['close'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      myInfo: this.$store.getters.me,
      bankList: this.$t('refund.refund_bank').split('_'),
      refundBonus: null,
      owner: loadData('account_holder', ''),
      phone: loadData('phone_number', ''),
      resident: loadData('resident_number', ''),
      address: loadData('address', ''),
      bank: loadData('bank', -1),
      account: loadData('account_number', ''),
      cash: 0,
      price: 0,
      selectedBank: "",
    }
  },
  watch: {
    show(show) {
      if (show) {
        // dialog show
        this.myInfo = this.$store.getters.me
        this.owner = loadData('account_holder', '')
        this.phone = loadData('phone_number', '')
        this.resident = loadData('resident_number', '')
        this.address = loadData('address', '')
        this.bank = loadData('bank', -1)
        this.account = loadData('account_number', '')
        this.cash = 0
        this.price = 0
        this.selectedBank = this.bankList[0]

        this.getRefundBonus()

        document.addEventListener('keyup', this.onKeyPress);
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.ask-content').parentElement;
        let activeDlgList = document.querySelectorAll('.modal-wrapper');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    getRefundBonus: function () {
      let _app = this;
      requestPost(
        'user/getAverageVideoChatTimesAndReviewValues',
        {
          uid: _app.myInfo.uid,
          peer_uid: this.myInfo.uid
        },
        response => {
          _app.reviewValue = response.review_value;
          if (_app.reviewValue >= 2.5 && _app.reviewValue < 3) {
            _app.refundBonus = 3;
          } else if (_app.reviewValue >= 3 && _app.reviewValue < 3.5) {
            _app.refundBonus = 6;
          } else if (_app.reviewValue >= 3.5 && _app.reviewValue < 4) {
            _app.refundBonus = 9;
          } else if (_app.reviewValue >= 4 && _app.reviewValue < 4.5) {
            _app.refundBonus = 15;
          } else if (_app.reviewValue >= 4.5 && _app.reviewValue < 5) {
            _app.refundBonus = 17;
          } else if (_app.reviewValue >= 5) {
            _app.refundBonus = 20;
          }
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    close() {
      this.$emit('close');
    },
    onPlus: function () {
      if (this.cash + 10000 > this.myInfo.cash) {
        return;
      }
      this.cash += 10000;
      this.price = this.cash + Math.floor(this.cash * this.bonus / 100);
    },
    onMinus: function () {
      if (this.cash < 1) {
        return;
      }
      this.cash -= 10000;
      this.price = this.cash + Math.floor(this.cash * this.bonus / 100);
    },
    onRequest: function () {
      let _app = this;
      if (this.owner == '') {
        showToast(this.$t('refund.refund_03'));
        return;
      }
      if (this.resident == '') {
        showToast(this.$t('refund.refund_04'));
        return;
      }
      if (this.phone == '') {
        showToast(this.$t('refund.refund_05'));
        return;
      }
      if (this.address == '') {
        showToast(this.$t('refund.refund_06'));
        return;
      }
      if (this.account == '') {
        showToast(this.$t('refund.refund_08'));
        return;
      }
      if (this.cash < 30000) {
        showToast(this.$t('refund.refund_10'));
        return;
      }
      if (this.cash > this.myInfo.cash) {
        showToast(this.$t('refund.refund_11'));
        return;
      }

      requestPost(
        'refund/requestRefund',
        {
          uid: this.myInfo.uid,
          name: this.owner,
          resident_number: this.resident,
          phone_number: this.phone,
          address: this.address,
          bank: this.bankList[this.bank],
          account: this.account,
          payment: this.cash,
          refund_bonus: this.bonus
        },
        () => {
          _app.myInfo.cash -= _app.cash;
          _app.$store.dispatch('setMyInfo', _app.myInfo);

          saveData('account_holder', _app.owner);
          saveData('phone_number', _app.phone);
          saveData('resident_number', _app.resident);
          saveData('address', _app.address);
          saveData('bank', _app.bank);
          saveData('account_number', _app.account);

          showToast(_app.$t('refund.refund_12'));

          _app.$router.back();
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onSelect: function (item, idx) {
      $(".dropdown dd ul").hide();
      this.selectedBank = item
    },

    toggle: function () {
      $(".dropdown dd ul").toggle();
    },
  }
}
</script>
