<template>
  <div class="contents">
    <div class="title">
      <h1 v-html="$t('common.top_title', {nickname: myInfo.user_nickname})"></h1>
      <p>{{ $t('common.top_subtitle') }}</p>
    </div>
    <div class="smenu">
      <div class="my_profile">
        <UserImage :gender="myInfo.user_sex" :image="myInfo.user_profile_image"/>
        <h1>
          <img  style="width: 36px; height: 30px; margin-top: -5px;" :src="require('../../assets/flag/' + myInfo.user_country_code + '.png')">
          {{ myInfo.user_nickname }}
          <span :class="myInfo.user_sex == 0  ? 'male' : 'female'">
            ({{
              (myInfo.user_sex == 0 ? $t('common.male') : $t('common.female')) + ", " +
              myInfo.user_age + " " + $t('common.years')
            }})
          </span>
        </h1>
        <p>{{ $t('more.more_45') }}</p>
        <a href="javascript:" @click="onProfile()">{{ $t('more.more_35') }}</a>
        <a href="javascript:" @click="onAlbum()">
          <img src="../../assets/img/ico_pro03.png">{{ $t('more.more_30') }}
        </a>
        <div class="my_profile_btn">
          <a href="javascript:" @click="onHistory">{{ $t('more.more_13') }}<img src="../../assets/img/arrow_left.png"></a>
          <a href="javascript:" @click="onCheckIn">{{ $t('more.more_31') }}<img src="../../assets/img/arrow_left.png"></a>
          <a v-if="myInfo.user_sex == 1 && myInfo.user_country_code == 'jp' && myInfo.refund_visible == 1" href="javascript:" @click="onRefund">{{ $t('more.refund') }}<img src="../../assets/img/arrow_left.png"></a>
        </div>
      </div>
    </div>

    <div class="warp">
      <div class="my_btn">
        <a href="javascript:" @click="onCharge()">
          <img src="../../assets/img/ico_my_01_jp.png">{{ $t('more.more_09') }}<img src="../../assets/img/arrow_left.png">
        </a>
        <a href="javascript:" @click="onAsk()">
          <img src="../../assets/img/ico_my_02.png">{{ $t('more.more_14') }}<img src="../../assets/img/arrow_left.png">
        </a>
        <a href="javascript:" @click="onAnnounce()">
          <img src="../../assets/img/ico_my_03.png">{{ $t('more.more_16') }}<img src="../../assets/img/arrow_left.png">
        </a>
        <a href="javascript:" @click="onGuide()">
          <img src="../../assets/img/ico_my_04.png">{{ $t('more.more_15') }}<img src="../../assets/img/arrow_left.png">
        </a>
        <a href="javascript:" @click="onNotifySetting">
          <img src="../../assets/img/ico_my_05.png">{{ $t('more.more_44') }}<img src="../../assets/img/arrow_left.png">
        </a>
        <a href="javascript:" @click="onGiftItems()">
          <img src="../../assets/img/ico_my_gift.png">{{ $t('more.more_10') }}<img src="../../assets/img/arrow_left.png">
        </a>
      </div>
      <div class="my_des">
        <p>{{ $t('more.more_46') }}</p>
        <span>{{ $t('more.more_47') }}</span>
      </div>
      <div class="my_btn" style="margin-top: 30px; width: 450px;">
        <a class="leave_btn" href="javascript:" @click="onLeave()">
          {{ $t('more.leave') }}
        </a>
      </div>
      <div class="clear"></div>
    </div>
  </div>
  <Ask
    :show="showAskPopup"
    @close="showAskPopup = false"/>
  <Refund
    :show="showRefundPopup"
    @close="showRefundPopup = false"/>
</template>

<style lang="scss" scoped src="./More.scss"></style>

<script>
import {requestPost, showToast} from "../../lib/util";
import Ask from "@/views/dialog/Ask";
import Refund from "@/views/dialog/Refund";
import UserImage from "@/components/UserImage";

export default {
  name: 'More',
  components: {
    UserImage,
    Ask,
    Refund,
  },
  data() {
    return {
      myInfo: this.$store.getters.me,
      setting: this.$store.getters.setting,
      updateInfo: this.$store.getters.updateInfo,
      showAskPopup: false,
      showHistory: false,
      showNotification: false,
      showJjimModal: false,
      showRefundPopup: false,
    }
  },
  methods: {
    onAlbum: function () {
      window.localStorage.setItem('Album.UserInfo', JSON.stringify(this.myInfo));
      this.$router.push('/album/' + this.myInfo.uid);
    },
    onNotice: function () {
      this.showNotification = true;
    },
    onCheckIn: async function () {
      let _app = this;
      requestPost(
        'user/checkIn',
        {
          uid: this.myInfo.uid
        },
        response => {
          _app.myInfo.points = response.point;
          _app.$store.dispatch('setMyInfo', _app.myInfo);

          showToast(this.$t('msg.check_in', {point: _app.setting.point_checkin}));
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onAnnounce: function () {
      this.$router.push('/announce');
    },
    onAsk: function () {
      this.showAskPopup = true;
    },
    onCharge: function () {
      this.$router.push('/charge');
    },
    onRefund: function () {
      this.$router.push('/refund');
    },
    onGiftItems: function () {
      this.$router.push('/gift-items');
    },
    onHistory: function () {
      this.$router.push('/history');
    },
    onUpdateCheck: function () {
      // let currentVersion = device.version ? device.version : '';
      // if (!updateInfo || currentVersion >= updateInfo.version_max) {
      //   showToast($t('version_4'));
      //   return;
      // }
      //
      // let url = '';
      // switch (updateInfo.update_market_code) {
      //   case 'google':
      //     url = `market://details?id=${updateInfo.package}`;
      //     break;
      //   case 'onestore':
      //     url = `onestore://common/product/${updateInfo.package}`;
      //     break;
      //   default:
      //     url = updateInfo.package;
      // }
      //
      // go2Url(url);
    },
    onShare: function () {
      // TODO: share
    },
    onProfile: function () {
      this.$router.push('/profile-edit');
    },
    onGuide: function () {
      this.$router.push('/guide');
    },
    onTransfer: function () {
      this.$router.push('/transfer');
    },
    onNotifySetting: function () {
      this.$router.push('/notify-setting');
    },
    onLeave: function () {
      let _app = this;
      this.$root.showConfirmDlg(
        this.$t('more.leave'),
        this.$t('more.leave_message'),
        this.$t('common.ok'),
        this.$t('common.cancel'),
        () => {
          requestPost(
            'user/leave',
            {
              uid: _app.myInfo.uid
            },
            response => {
              _app.$router.replace({
                name: 'Logout'
              });
            },
            (code, msg) => {
              showToast(msg);
            }
          );
        },
      );
    },
  }
}
</script>
