<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_ask" @click="$event.stopPropagation()">
        <div class="ask_top">
          <h1>{{ $t('ask.ask') }}</h1>
          <p>{{ $t('ask.desc') }}</p>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../assets/img/btn_close.png">
          </a>
        </div>
        <div class="ask_box">
          <input teyp="text" v-model="email" :placeholder="$t('ask.ask_09')">
          <textarea v-model="content" :placeholder="$t('ask.ask_03')"></textarea>
          <a href="javascript:" v-for="(item, idx) in imageList" v-if="image != null" @click="onDelete(idx)">
            <img :src="item" class="image"/>
          </a>
          <a href="javascript:" @click="onGallery">
            <img src="../../assets/img/ico_chat_plus.png" class="add">
          </a>
          <span>{{ $t('ask.upload_desc') }}</span>
          <div class="agree">
            <p><input type="checkbox" v-model="agree">{{ $t('ask.agree') }}</p>
            <span>{{ $t('ask.agree_content') }}</span>
          </div>
        </div>
        <div class="ask_btn">
          <a href="javascript:" @click="onAsk">{{ $t('ask.ask_06') }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./Ask.scss"></style>

<script>
import {UPLOAD_ASK_IMAGE} from "../../lib/constant";
import {requestPost, requestUpload, showToast} from "../../lib/util";

export default {
  name: 'Ask',
  components: {
  },
  emits: ['close'],
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let myInfo = this.$store.getters.me;

    return {
      myInfo: myInfo,
      email: '',
      content: '',
      image: null,
      imageList: [],
      fileList: [],
      file: null,
      agree: false,
      backButtonRouteGuard: null,
    }
  },
  watch: {
    show(show) {
      if (show) {
        // dialog show
        this.email = '';
        this.content = '';
        this.image = null;
        this.file = null;
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_ask').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    close() {
      this.$emit('close');
    },
    onDelete: function (idx) {
      this.imageList.splice(idx, 1)
      this.fileList.splice(idx, 1)
    },
    onGallery: function () {
      this.$root.takePhoto(img => {
        let _app = this;
        if (!img) {
          showToast(this.$t('app_error.upload_file_not_exist'));
          return;
        }
        if (img) {
          _app.file = img;
          _app.fileList.push(img);
          let fr = new FileReader();
          fr.onload = function () {
            _app.image = fr.result;
            _app.imageList.push(_app.image)
          };
          fr.readAsDataURL(img);
        }
      });
    },
    onAsk: async function () {
      let _app = this
      if (this.email == '') {
        showToast(this.$t('ask.ask_01'));
        return;
      }
      if (this.content == '') {
        showToast(this.$t('ask.ask_03'));
        return;
      }
      if (!this.agree) {
        showToast(this.$t('ask.agree'));
        return;
      }
      let image_url = '';
      for (let idx = 0; idx < this.fileList.length; idx++)
      {
        if (this.file != null) {
          image_url += await requestUpload(_app.fileList[idx], {
            uid: this.myInfo.uid,
            category: UPLOAD_ASK_IMAGE
          });
          image_url += "###"
        }
      }

      requestPost(
        'qna/ask',
        {
          uid: this.myInfo.uid,
          email: this.email,
          category: 'Other',
          query_content: this.content,
          attach_image_url: image_url
        },
        response => {
          showToast(_app.$t('ask.ask_08'));
          _app.$emit('close');
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
  }
}
</script>
